

@media only screen and (min-width: 768px) {
    .logo-mini {
        transition: all 300ms linear;
        opacity: 1;
        text-align: center;
        width: 90px;
        display: inline-block;
        max-height: 70px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 1px;
        color: inherit;
        font-family: Poppins, sans-serif;
    }
}

@media only screen and (max-width: 600px) {
    .logo-mini {
        transition: all 300ms linear;
        opacity: 1;
        text-align: start;
        width: 90px;
        display: inline-block;
        max-height: 70px;
        margin-left: -15px;
        margin-right: 16px;
        margin-top: 1px;
        color: inherit;
        font-family: Poppins, sans-serif;
    }
}