.ReactTable .rt-thead .rt-th:last-child {
    text-align: left !important;
  }

  .marginBottom {
    margin-bottom: 10px;
  }
  .next-button {
    float: right;
} 
.prev-button {
    float: left;
}
.no-padding {
    padding : 0px !important;
}
.mobile-view {
    display: none !important;
}
.page-header-title-mobile {
    margin-bottom: 15px;
    /* margin-right: 10px; */
    margin-top: 20vh;
    /* margin-left: 2rem; */
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    /* font-family: Rajdhani; */
}

.para-text-header {
    font-size: 14px;
    margin-bottom: 3px;
    text-align: start;
    color: #000;
    line-height: initial;
}
.para-text-bold {
    font-size: 14px;
    margin-bottom: 3px;
    text-align: start;
    color: #000;
    font-weight: bold;
}

  @media only screen and (min-width: 768px) {
    .page-header-title {
        margin-bottom: 30px;
        margin-right: 10px;
        margin-top: 13vh;
        margin-left: 4rem;
        text-align: start;
        font-size: 24px;
        font-weight: bold;
        color: #000;
        font-family: Rajdhani;
    }
    .next-button {
        float: right;
    } 
    .prev-button {
        float: left;
    }
    .tr-title {
        margin-top: 2px;
        margin-bottom: 2px;
        margin-right: 60px;
        text-align: end;
        font-size: 22px;
        font-weight: bold;
        font-family: Rajdhani;
        color: #000;

    }
    .mobile-view {
        display: none !important;
    }
    .trucker-title {
        margin-bottom: 2px;
        margin-right: 10px;
        margin-left: 4rem;
        text-align: start;
        font-size: 22px;
        font-weight: bold;
        font-family: Rajdhani;
        color: #000;
    }
    .react-table-container {
        margin-top: 20px !important;
        margin-left: 4rem !important;
    }
       
  }

  @media only screen and (max-width: 820px) {

  }

  @media only screen and (max-width: 600px) {
    .tr-title {
        margin-top: 2px;
        margin-bottom: 2px;
        margin-left: 2rem;
        text-align: start;
        font-size: 16px;
        font-weight: bold;
        font-family: Rajdhani;
        color: #000;
    } 
    .trucker-title {
        margin-bottom: 2px;
        margin-right: 10px;
        margin-left: 2rem;
        text-align: start;
        font-size: 16px;
        font-weight: bold;
        font-family: Rajdhani;
        color: #000;
    }
    .react-table-container {
        margin-top: 20px !important;
        margin-left: 2rem !important;
    }
    .page-header-title {
        margin-bottom: 30px;
        margin-right: 10px;
        margin-top: 13vh;
        margin-left: 2rem;
        text-align: start;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        font-family: Rajdhani;
    }
    .next-button {
        float: left;
    } 
    .prev-button {
        float: left;
    } 
    .mobile-view {
        display: inline !important;
    }
  }