.location-search-input {
    font-size: 12px;
    width: 100%;
    padding: 10px;
    border: 1px solid #0000003b;
    border-radius: 3px;
    position: relative;
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    color: #404346d4;
}